<template>
  <div class="table-store-export">
    <div class="form-area table-search">
      <el-form
        class="demo-form-inline"
        ref="form"
        :model="form"
        label-position="left"
        label-width="82px"
        :inline="true"
        :rules="rules"
      >
        <el-form-item label="所属车队：">
          <companySelectForSearch
            ref="companySelectForSearch"
            :multiple="true"
            :searchable="true"
            @getValue="getGroupIds"
          />
        </el-form-item>

        <el-form-item label="车牌号：">
          <car-tree @getData="getVehIds" ref="carTree"></car-tree>
        </el-form-item>

        <el-form-item label="报警类型：">
          <el-cascader
            v-model="form.alarmType"
            :options="alarmOptions"
            :props="affirmAlarmProps"
            size="small"
            collapse-tags
            clearable
            filterable
          ></el-cascader>
        </el-form-item>

        <el-form-item label="报警等级：" prop="alarmLevel">
          <el-select clearable v-model="form.alarmLevel" placeholder="请选择">
            <el-option
              v-for="item in dictList.alarmLevelList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="开始时间：" prop="beginTime">
          <el-date-picker
            v-model="form.beginTime"
            type="datetime"
            placeholder="选择开始时间"
            :clearable="false"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="结束时间：" prop="endTime">
          <el-date-picker
            v-model="form.endTime"
            type="datetime"
            placeholder="选择结束时间"
            :clearable="false"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="报警来源：">
          <el-select clearable v-model="form.alarmSource" placeholder="请选择">
            <el-option
              v-for="item in dictList.alarmSourceList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="处理状态：">
          <el-select
            clearable
            v-model="form.disposeStatus"
            placeholder="请选择"
          >
            <el-option
              v-for="item in dictList.disposeStatusList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报警判定：">
          <el-select clearable v-model="form.alarmConfirm" placeholder="请选择">
            <el-option
              v-for="item in alarmConfirmList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="alarm-btn">
          <el-button
            size="small"
            type="primary"
            v-if="$store.state.menu.nowMenuList.indexOf('导出') >= 0"
            :loading="downLoadStatus"
            @click="exportData"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {

} from '@/api/lib/api.js'
import {
  getStartDate,
  getCurentDate,
  checkTimeLimit,
  checkTimeLimitDay,
  checkLimitDate,
  formatDate,
  formatDict,
  getCurent
} from '@/common/utils/index'
import companySelectForSearch from '@/components/treeSelect/companySelectForSearch.vue'
import carTree from '@/components/carTree/carSearchTree.vue';
import { addExcelExport } from '@/api/lib/refreshAlarm.js'
export default {
  name: '',
  components: {
    companySelectForSearch,
    carTree
  },
  props: {
    dictList: {
      type: Object,
      default: () => { }
    },
    alarmOptions: {
      type: Array,
      default: () => []
    }
  },
  data () {
    // 不能超前选择
    let validateDateForward = (rule, value, callback) => {
      if (new Date() < value) {
        callback(new Error('不能选择未来时间'))
      } else {
        callback()
      }
    }

    // 详细时间段
    let validateDateTime1 = (rule, value, callback) => {
      if (value != null && !checkTimeLimit(value, this.form.endTime)) {
        callback(new Error('开始时间必须小于结束时间'))
      }
      if (value != null && !checkTimeLimitDay(value, this.form.endTime, 1)) {
        callback(new Error('只能查询1天内的数据'))
      } else {
        callback()
      }
    }
    let validateDateTime2 = (rule, value, callback) => {
      if (value != null && !checkTimeLimit(this.form.beginTime, value)) {
        callback(new Error('结束时间必须大于开始时间'))
      }
      if (value != null && !checkTimeLimitDay(this.form.beginTime, value, 1)) {
        callback(new Error('只能查询1天内的数据'))
      } else {
        callback()
      }
    }
    return {
      downLoadStatus: false,
      alarmConfirmList: [
        {
          dictValue: '全部',
          dictCode: ''
        },
        {
          dictValue: '待确认',
          dictCode: '0'
        },
        {
          dictValue: '确认报警',
          dictCode: '1'
        },
        {
          dictValue: '误报',
          dictCode: '3'
        },
        {
          dictValue: '报备',
          dictCode: '2'
        },
      ],
      affirmAlarmProps: {
        value: 'value',
        label: 'label',
        children: 'children',
        multiple: true
      },
      form: {
        system: sessionStorage.getItem('system').toString(), //对应系统
        beginTime: new Date(getStartDate()), // 开始时间
        endTime: new Date(getCurentDate()), // 结束时间
        companyIds: [],
        vehicleNos: [],
        alarmLevel: '', // 报警等级
        alarmType: [], // 报警类型
        alarmSource: '', // 报警来源
        credentialsNo: [], // 驾驶员
        // timeoutType: '', // 超时处理
        disposeStatus: '', // 处理状态
        alarmConfirm: '',//报警判定
      },
      rules: {
        beginTime: [
          { required: true, message: '请选择', trigger: 'blur' },
          { validator: validateDateForward, trigger: 'blur' },
          { validator: validateDateTime1, trigger: 'blur' }
        ],
        endTime: [
          { required: true, message: '请选择', trigger: 'blur' },
          { validator: validateDateForward, trigger: 'blur' },
          { validator: validateDateTime2, trigger: 'blur' }
        ],
        alarmType: [
          {
            required: true,
            message: '请至少选择一个报警类型',
            trigger: 'blur'
          }
        ]
      },
      pickerOptions: {
        disabledDate (time) {
          return checkLimitDate(time)
        },
        shortcuts: [
          {
            text: '今天',
            onClick (picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
    }
  },

  methods: {
    exportData () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.downLoadStatus = true;
          let data = {...this.form}
          if (data.alarmType.length >= 1) {
            var newAlarmType = []
            var temAlarmType = [...new Set([...this.form.alarmType.flat(Infinity)])]
            temAlarmType.forEach((item) => {
              if (item !== 'D0008' && item !== 'D0009' && item !== 'D0010') {
                newAlarmType.push(item)
              }
            })
            data.alarmType = newAlarmType
          }
          let params = {
            baseUrl: 'base',
            fileName: `报警历史数据-${getCurent()}`,
            queryParam: JSON.stringify(data),
            queryPath: '/historyAlarm/alarmDataExport'
          }
          addExcelExport(params)
        .then((res) => {
          this.$message.success(res.msg);
          this.downLoadStatus = false;
          this.$store.dispatch('setRefreshStatus', true);
          this.$emit('close');
        })
        .catch(() => {
          this.downLoadStatus = false
        })
        }
      })

    },
    // 获取车组数据
    getGroupIds (val) {
      this.form.companyIds = [...val]
    },
    // 获取车辆数据
    getVehIds (val) {
      this.form.vehicleNos = [...val]
    },
  },
  created () {

  },
  mounted () {

  },

}
</script>

<style lang="scss" scoped>
.alarm-dia {
  height: calc(79vh - 149px);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  @include themify() {
    .fixed {
      flex: 1;
    }
    .scroll {
      overflow-y: auto;
    }
    .title {
      color: themed('n7');
      font-size: 14px;
      margin-bottom: 16px;
    }
    .header-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 6px;
      span {
        margin-right: 1vw;
      }
    }
    .information,
    .record,
    .deal {
      background-color: themed('n1');
    }
    .information {
      padding: 12px;
      margin-bottom: 16px;
      .head {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        color: themed('n8');
        font-size: 14px;
        margin-bottom: 16px;
        p:nth-of-type(1) {
          margin-bottom: 16px;
        }
        // p:nth-of-type(4n) {
        //   text-align: right;
        // }
      }
      .split-line {
        border-bottom: 1px solid;
        border-bottom-color: themed('n4');
        margin: 0 -12px 12px;
      }
    }
    .record {
      padding: 12px;
      margin-bottom: 16px;
    }
    .deal {
      padding: 16px;
    }
    .sign {
      color: themed('b4');
    }
    .pointer {
      cursor: pointer;
    }
  }
}

.batch {
  height: calc(40vh - 149px);
}
/deep/.deal-form {
  .el-form-item {
    &:nth-of-type(1),
    &:nth-of-type(2) {
      width: 45%;
    }
    &:last-of-type {
      width: 100%;
      display: flex;
      .el-form-item__content {
        flex: 1;
      }
    }
  }
}
/deep/.el-carousel {
  width: 600px;
  img {
    width: 30vw;
  }
}
</style>
