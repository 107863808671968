<template>
  <div class="alarm-deal">
    <div class="content">
      <div class="form-area table-search">
        <el-form
          class="demo-form-inline"
          ref="form"
          :model="form"
          label-position="left"
          label-width="82px"
          :inline="true"
          :rules="rules"
        >
          <el-form-item label="所属车队：">
            <companySelectForSearch
              ref="companySelectForSearch"
              :multiple="true"
              :searchable="true"
              @getValue="getGroupIds"
            />
          </el-form-item>

          <el-form-item label="车牌号：">
            <car-tree
              @getData="getVehIds"
              ref="carTree"
              :companyIdList="choosecph"
            ></car-tree>
          </el-form-item>

          <el-form-item label="报警类型：">
            <el-cascader
              v-model="form.alarmType"
              :options="alarmOptions"
              :props="affirmAlarmProps"
              size="small"
              collapse-tags
              clearable
              filterable
            ></el-cascader>
          </el-form-item>

          <el-form-item label="报警等级：" prop="alarmLevel">
            <el-select clearable v-model="form.alarmLevel" placeholder="请选择">
              <el-option
                v-for="item in dictList.alarmLevelList"
                :label="item.dictValue"
                :value="item.dictCode"
                :key="item.dictCode"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="开始时间：" prop="beginTime">
            <el-date-picker
              v-model="form.beginTime"
              type="datetime"
              placeholder="选择开始时间"
              :clearable="false"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="结束时间：" prop="endTime">
            <el-date-picker
              v-model="form.endTime"
              type="datetime"
              placeholder="选择结束时间"
              :clearable="false"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="报警来源：">
            <el-select
              clearable
              v-model="form.alarmSource"
              placeholder="请选择"
            >
              <el-option
                v-for="item in dictList.alarmSourceList"
                :label="item.dictValue"
                :value="item.dictCode"
                :key="item.dictCode"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="处理状态：">
            <el-select
              clearable
              v-model="form.disposeStatus"
              placeholder="请选择"
            >
              <el-option
                v-for="item in dictList.disposeStatusList"
                :label="item.dictValue"
                :value="item.dictCode"
                :key="item.dictCode"
              ></el-option>
            </el-select>
          </el-form-item>

          <!-- <el-form-item label="报警状态：">
            <el-select
              clearable
              v-model="form.alarmStatus"
              placeholder="请选择"
            >
              <el-option
                v-for="item in dictList.alarmStatusList"
                :label="item.dictValue"
                :value="item.dictCode"
                :key="item.dictCode"
              ></el-option>
            </el-select>
          </el-form-item> -->

          <!-- <el-form-item label="超时处理：">
            <el-select
              clearable
              v-model="form.timeoutType"
              placeholder="请选择"
            >
              <el-option
                v-for="item in dictList.timeoutTypeList"
                :label="item.dictValue"
                :value="item.dictCode"
                :key="item.dictCode"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="报警判定：">
            <el-select
              clearable
              v-model="form.alarmConfirm"
              placeholder="请选择"
            >
              <el-option
                v-for="item in alarmConfirmList"
                :label="item.dictValue"
                :value="item.dictCode"
                :key="item.dictCode"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="alarm-btn">
            <el-button size="small" type="primary" @click="search"
              >查询</el-button
            >
            <el-button size="small" type="default" @click="resetForm"
              >重置</el-button
            >
            <el-button
              size="small"
              type="primary"
              @click="batchDeal"
              v-if="$store.state.menu.nowMenuList.indexOf('批量处理') >= 0"
              >批量处理</el-button
            >
            <el-button
              size="small"
              type="primary"
              v-if="$store.state.menu.nowMenuList.indexOf('导出') >= 0"
              :disabled="tableData.length === 0"
              :loading="downLoadStatus"
              @click="exportData"
              >导出</el-button
            >
            <el-button
              size="small"
              type="primary"
              v-if="$store.state.menu.nowMenuList.indexOf('历史报警导出') >= 0"
              @click="()=>tableStoreVisible = true"
              >历史报警导出</el-button
            >
          </el-form-item>
        </el-form>
      </div>

      <el-table
        :data="tableData"
        height="calc(100vh - 350px)"
        @selection-change="handleSelectionChange"
        stripe
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column
          prop="alarmType"
          label="报警类型"
          width="200"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span
              v-if="$store.state.menu.nowMenuList.indexOf('处理') >= 0"
              class="alarmName"
              @click="showAlarmDetail(scope.row)"
              >{{ alarmAllObj[scope.row.alarmType] }}</span
            >
            <span v-else>{{ alarmAllObj[scope.row.alarmType] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="alarmLevel" label="报警等级">
          <template slot-scope="scope">
            {{ dictObj.alarmLevelObj[Number(scope.row.alarmLevel)] }}
          </template>
        </el-table-column>
        <el-table-column
          prop="cph"
          label="车牌号"
          width="120"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="companyName"
          label="所属车队"
          width="160"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="driverName"
          label="驾驶员姓名"
          width="120"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column prop="alarmSource" label="报警来源">
          <template slot-scope="scope">
            {{ dictObj.alarmSourceObj[Number(scope.row.alarmSource)] }}
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="speedingVelocity"
          label="车速/限速"
          width="120"
        ></el-table-column> -->
        <!-- alarmSignTime -->
        <el-table-column
          prop="time"
          label="报警发生时间"
          :show-overflow-tooltip="true"
          width="170"
        >
          <template slot-scope="scope">
            {{ scope.row | filterAlarmTime(scope.row.time) }}
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="alarmEndTime"
          label="报警结束时间"
          :show-overflow-tooltip="true"
          width="170"
        >
          <template slot-scope="scope">
            {{ scope.row.alarmEndTime?scope.row.alarmEndTime:'-' }}
          </template>
        </el-table-column> -->
        <!-- <el-table-column
          prop="timeoutCount"
          label="持续时间(秒)"
          width="120"
        >
          <template slot-scope="scope">
            {{ scope.row | filterCountiue(scope.row.alarmSignTime,scope.row.alarmEndTime) }}
          </template>
        </el-table-column> -->
        <el-table-column
          prop="speedingVelocity"
          label="报警最高车速/限速值"
          width="180"
        ></el-table-column>
        <!-- <el-table-column prop="alarmStatus" label="是否超时处理" width="140">
          <template slot-scope="scope">
            {{ scope.row.alarmTimeOut===0?'未超时':'超时'}}
          </template>
        </el-table-column> -->

        <!-- <el-table-column prop="alarmStatus" label="报警状态">
          <template slot-scope="scope">
            {{ dictObj.alarmStatusObj[Number(scope.row.alarmStatus)] }}
          </template>
        </el-table-column> -->
        <el-table-column prop="disposeStatus" label="处理状态">
          <template slot-scope="scope">
            {{ dictObj.disposeStatusObj[Number(scope.row.disposeStatus)] }}
          </template>
        </el-table-column>
        <el-table-column prop="disposeStatus" label="报警确认">
          <template slot-scope="scope">
            {{ alarmConfirmobj[Number(scope.row.alarmConfirm)] }}
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <div class="pagination">
        <!-- <el-pagination
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :current-page.sync="form.currentPage"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          background
          :total="total"
        ></el-pagination> -->
        <span>共 {{ total }} 条</span>
        <el-select
          v-model="limit"
          @change="search"
          class="page-select"
          placeholder="请选择"
        >
          <el-option
            v-for="item in lismitList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
        <el-button
          size="small"
          type="primary"
          :loading="loading"
          :disabled="!pageTokenList.length"
          @click="prePage"
          >上一页</el-button
        >
        <el-button
          size="small"
          type="primary"
          :loading="loading"
          :disabled="!nextToken"
          @click="nextPage"
          >下一页</el-button
        >
      </div>
    </div>

    <el-dialog
      title="报警详情"
      :visible.sync="dialogVisible"
      width="70%"
      top="10vh"
      custom-class="alarm-detail"
    >
      <div class="body">
        <alarm-dialog
          :detailData="alarmDetail"
          :typeObj="dictObj.typeObj"
          :alarmLevelObj="dictObj.alarmLevelObj"
          :alarmSourceObj="dictObj.alarmSourceObj"
          :disposeWayList="dictList.disposeWayList"
          :alarmConfirmList="dictList.alarmConfirmList"
          :disposeTypeObj="dictObj.disposeTypeObj"
          :disposeWayObj="dictObj.disposeWayObj"
          ref="alarmDia"
          @cancelDialog="cancelDialog"
          v-if="dialogVisible"
          :isBatch="isBatch"
          :alarmList="multipleSelection"
          :alarmAllObj="alarmAllObj"
          @changeLoading="changeLoading"
        ></alarm-dialog>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="default"
          size="small"
          @click="dialogVisible = false"
          v-if="!fileDialog"
          >取消</el-button
        >

        <el-button
          type="primary"
          size="small"
          :loading="disposeLoading"
          @click="onSaveDetail"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- tableStore导出历史数据 -->
    <el-dialog
      title="报警历史数据导出"
      :visible.sync="tableStoreVisible"
      width="70%"
      top="10vh"
      custom-class="alarm-detail"
    >
      <div class="body">
        <tableStoreExport :dictList="dictList" :alarmOptions="alarmOptions" @close="close"></tableStoreExport>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import alarmDia from '../../layout/components/alarmDetailDialog'
import carTree from '@/components/carTree/carSearchTree.vue'
import companySelectForSearch from '@/components/treeSelect/companySelectForSearch.vue'
import tableStoreExport from './components/tableStoreExport.vue';
import {
  getStartDate,
  getCurentDate,
  checkTimeLimit,
  checkTimeLimitDay,
  checkLimitDate,
  formatDate,
  formatDict,
  getCurent
} from '@/common/utils/index'

import {
  getAlarmNameList,
  queryAlarmListV2,
  queryDictsByCodes
} from '@/api/lib/api.js'
import moment from "moment";
import { addExcelExport } from '@/api/lib/refreshAlarm.js'

export default {
  name: 'alarmDeal',
  components: {
    'alarm-dialog': alarmDia,
    carTree,
    companySelectForSearch,
    tableStoreExport
  },
  computed: {
    ...mapGetters({
      nowMenuList: 'nowMenuList'
    })
  },
  data () {
    // 不能超前选择
    let validateDateForward = (rule, value, callback) => {
      if (new Date() < value) {
        callback(new Error('不能选择未来时间'))
      } else {
        callback()
      }
    }

    // 详细时间段
    let validateDateTime1 = (rule, value, callback) => {
      if (value != null && !checkTimeLimit(value, this.form.endTime)) {
        callback(new Error('开始时间必须小于结束时间'))
      }
      if (value != null && !checkTimeLimitDay(value, this.form.endTime)) {
        callback(new Error('只能查询31天内的数据'))
      } else {
        callback()
      }
    }
    let validateDateTime2 = (rule, value, callback) => {
      if (value != null && !checkTimeLimit(this.form.beginTime, value)) {
        callback(new Error('结束时间必须大于开始时间'))
      }
      if (value != null && !checkTimeLimitDay(this.form.beginTime, value)) {
        callback(new Error('只能查询31天内的数据'))
      } else {
        callback()
      }
    }
    return {
      form: {
        currentPage: 1,
        pageSize: 10,
        system: sessionStorage.getItem('system').toString(), //对应系统
        beginTime: new Date(getStartDate()), // 开始时间
        endTime: new Date(getCurentDate()), // 结束时间
        companyIds: [],
        vehicleNos: [],
        alarmLevel: '', // 报警等级
        alarmType: [], // 报警类型
        alarmSource: '', // 报警来源
        credentialsNo: [], // 驾驶员
        // timeoutType: '', // 超时处理
        disposeStatus: '', // 处理状态
        alarmConfirm: '',//报警判定
      },
      tableData: [],
      total: 0,
      dictList: {},
      dictObj: {},
      multipleSelection: [],
      rules: {
        beginTime: [
          { required: true, message: '请选择', trigger: 'blur' },
          { validator: validateDateForward, trigger: 'blur' },
          { validator: validateDateTime1, trigger: 'blur' }
        ],
        endTime: [
          { required: true, message: '请选择', trigger: 'blur' },
          { validator: validateDateForward, trigger: 'blur' },
          { validator: validateDateTime2, trigger: 'blur' }
        ],
        // alarmLevel: [ { required: true, message: "请至少选择一个报警等级", trigger: "blur" } ],
        alarmType: [
          {
            required: true,
            message: '请至少选择一个报警类型',
            trigger: 'blur'
          }
        ]
      },
      props: {
        label: 'name',
        children: 'zones',
        isLeaf: 'leaf'
      },
      affirmAlarmProps: {
        value: 'value',
        label: 'label',
        children: 'children',
        multiple: true
      },
      alarmOptions: [],
      alarmAllOptions: [],
      alarmAllObj: {},
      pickerOptions: {
        disabledDate (time) {
          return checkLimitDate(time)
        },
        shortcuts: [
          {
            text: '今天',
            onClick (picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      dialogVisible: false,
      fileDialog: false,
      alarmID: null,
      choosecph: [],
      alarmDeatil: {},
      exportForm: {},
      downLoadStatus: false,
      alarmConfirmList: [
        {
          dictValue: '全部',
          dictCode: ''
        },
        {
          dictValue: '待确认',
          dictCode: '0'
        },
        {
          dictValue: '确认报警',
          dictCode: '1'
        },
        // {
        //   dictValue: '误报',
        //   dictCode: '3'
        // },
        {
          dictValue: '报备',
          dictCode: '2'
        },
      ],
      alarmConfirmobj: null,
      isBatch: false,//是否批量处理
      nextToken: null,//v3.0下一页请求数据依据
      pageTokenList: [],//暂存页面分页请求token的list
      loading: false,
      disposeLoading: false,
      lismitList: [
        {
          dictValue: '10',
          dictCode: '10'
        },
        {
          dictValue: '60',
          dictCode: '60'
        },
        {
          dictValue: '100',
          dictCode: '100'
        }
      ],
      limit: '10',
      tableStoreVisible: false,//tableStore导出历史数据 20230726 谢+郭（后台）
    }
  },
  filters: {
    filterCountiue (item, start, end) {
      let date = '-'
      if (start && end) {
        date = moment(end).diff(moment(start), 'seconds');
      }
      return date
    },
    filterAlarmTime (item, time) {
      let date = "-";
      if (time) {
        date = formatDate(time);
      }
      return date
    }
  },
  methods: {
    //tableStore导出历史数据
    close () {
      this.tableStoreVisible = false;
    },
    // 批量处理
    batchDeal () {
      if (this.multipleSelection.length === 0) {
        this.$message.warning('请选择报警记录')
      } else {
        let Ids = []
        this.multipleSelection.forEach((item) => {
          Ids.push(item.alarmNo)
        })
        this.isBatch = true
        this.alarmDetail = {}
        this.alarmID = Ids
        this.dialogVisible = true
        this.fileDialog = false
      }
    },
    //导出
    exportData () {
      this.downLoadStatus = true
      delete this.exportForm.currentPage
      delete this.exportForm.pageSize
      let data = {
        baseUrl: 'base',
        fileName: `报警信息-${getCurent()}`,
        queryParam: JSON.stringify(this.exportForm),
        queryPath: '/alarm/alarmDataExport'
      }
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg)
          this.downLoadStatus = false
          this.$store.dispatch('setRefreshStatus', true)
        })
        .catch(() => {
          this.downLoadStatus = false
        })
    },
    // 点击查询
    search () {
      this.form.currentPage = 1;
      this.nextToken = null;
      this.pageTokenList = [];
      this.getAlarmData();
    },
    // 重置表单
    resetForm () {
      this.form = {
        currentPage: 1,
        pageSize: 10,
        system: sessionStorage.getItem('system').toString(),
        beginTime: new Date(getStartDate()), // 开始时间
        endTime: new Date(getCurentDate()), // 结束时间
        companyIds: [],
        vehicleNos: [],
        alarmLevel: '', // 报警等级
        alarmType: [], // 报警类型
        alarmSource: '', // 报警来源
        credentialsNo: [], // 驾驶员
        // timeoutType: '', // 超时处理
        disposeStatus: '', // 处理状态
        alarmConfirm: '',//报警判定
      }
      this.nextToken = null;
      this.pageTokenList = [];
      this.$refs.companySelectForSearch.resetForm()
      this.$refs.carTree.resetTree()
      this.getAlarmData()
    },
    //下一页
    nextPage () {
      //点击下一页把当前页数据存进数组
      this.pageTokenList.push(this.nextToken);
      this.getAlarmData();
    },
    //获取当前页数据
    getCurrentPage () {
      this.nextToken = this.pageTokenList[this.pageTokenList.length - 1];
      this.getAlarmData();
    },
    //上一页、
    prePage () {
      //点击上一页，把数组最新的数据移除掉后，取最新数据就是上一页数据
      this.pageTokenList.pop();
      this.nextToken = this.pageTokenList[this.pageTokenList.length - 1];
      this.getAlarmData();
    },
    // 当前页改变
    handleCurrentChange (page) {
      this.form.currentPage = page
      this.getAlarmData()
    },
    // 每页显示条数改变
    handleSizeChange (size) {
      this.form.currentPage = 1
      this.form.pageSize = size
      this.getAlarmData()
    },
    // 表格多选
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    // 查看报警详情
    showAlarmDetail (row) {
      this.isBatch = false;
      this.alarmID = [row.alarmNo];
      this.alarmDetail = row
      this.dialogVisible = true
      this.fileDialog = false
    },
    // 获取报警类型
    getAlarmTypeFn () {
      const self = this
      self.alarmOptions = []
      self.alarmAllOptions = []
      self.dictList.alarmList.forEach((item) => {
        self.alarmOptions.push({
          label: item.dictValue,
          value: item.dictCode,
          children: []
        })
      })
      getAlarmNameList({ alarmSpecies: 'D0008,D0009', system: this.form.system }).then(
        (res) => {
          if (res.code === 1000) {
            // self.alarmOptions = res.data
            // res.data.forEach((item, index) => {
            //   self.alarmOptions[index].children.push({ label: self.dictObj[item.alarmSpecies + 'Obj'][item.alarmType], value: item.alarmType })
            // })
            res.data.forEach((item, index) => {
              if (item.alarmSpecies === 'D0008') {
                self.alarmOptions[0].children.push({
                  label: self.dictObj.D0008Obj[item.alarmType],
                  value: item.alarmType
                })
                self.alarmAllOptions.push({
                  dictValue: self.dictObj.D0008Obj[item.alarmType],
                  dictCode: item.alarmType
                })
              } else if (item.alarmSpecies === 'D0009') {
                self.alarmOptions[1].children.push({
                  label: self.dictObj.D0009Obj[item.alarmType],
                  value: item.alarmType
                })
                self.alarmAllOptions.push({
                  dictValue: self.dictObj.D0009Obj[item.alarmType],
                  dictCode: item.alarmType
                })
              } else {
                // self.alarmOptions[2].children.push({
                //   label: self.dictObj.D0010Obj[item.alarmType],
                //   value: item.alarmType
                // })
                // self.alarmAllOptions.push({
                //   dictValue: self.dictObj.D0010Obj[item.alarmType],
                //   dictCode: item.alarmType
                // })
              }
            })
            self.alarmAllObj = formatDict(self.alarmAllOptions)
          }
        }
      )
    },
    // 获取警报信息列表
    getAlarmData () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let data = { ...this.form }
          // 多维数组转成一维数组，并去掉一级报警类型
          if (data.alarmType.length >= 1) {
            var newAlarmType = []
            var temAlarmType = [...new Set([...this.form.alarmType.flat(Infinity)])]
            temAlarmType.forEach((item) => {
              if (item !== 'D0008' && item !== 'D0009' && item !== 'D0010') {
                newAlarmType.push(item)
              }
            })
            data.alarmType = newAlarmType
          }
          data.beginTime = formatDate(this.form.beginTime)
          data.endTime = formatDate(this.form.endTime)
          data.nextToken = this.nextToken;
          data.limit = this.limit;
          this.loading = true;
          queryAlarmListV2(data).then((res) => {
            if (res.code === 1000) {
              this.tableData = res.data.list || []
              this.total = res.data.total;
              this.nextToken = res.data.nextToken;
              //获取列表总页数-修复十倍数最后一页没有数据问题
              if (this.total <= (this.pageTokenList.length + 1) * 10) {
                this.nextToken = null;
              }
            } else {
              this.tableData = []
              this.total = 0
              this.nextToken = null;
              this.pageTokenList = [];
            }
            this.loading = false;
            this.exportForm = { ...data }
          })
        }
      })
    },
    // 获取车组数据
    getGroupIds (val) {
      this.form.companyIds = [...val]
    },
    // 获取车辆数据
    getVehIds (val) {
      this.form.vehicleNos = [...val]
    },
    // 提交报警详情
    onSaveDetail () {
      this.$refs.alarmDia.save()
    },
    // 关闭dialog
    cancelDialog (res) {
      this.dialogVisible = false
      do {
        if (!res) break
        if (res.length === 1) {
          this.$message.success(res[0])
          break
        }
        res.forEach((item, index) => {
          if (index === 0) {
            this.$message.warning(item)
          } else {
            setTimeout(() => {
              this.$message.error(item)
            }, 200 * index)
          }
        })
      } while (false)
      setTimeout(() => {
        this.nextPage();//获取当前页的数据
      }, 1000)
    },
    //防抖
    changeLoading (res) {
      this.disposeLoading = res;
    },
    // 报警弹窗
    fileOpenDialog (val) {
      this.fileDialog = val;
    },
    //获取字典值
    getDicts () {
      queryDictsByCodes({
        parentCodes:
          'BJDJ,CLLX,BJLY,CSCL,BJCLZT,BJZT,BJZL,BJCLFS,D0008,D0009,D0010,BJQR'
      }).then((res) => {
        if (res.code === 1000) {
          res.data.BJZL.splice(2, 1) //删除终端故障报警配置
          this.dictList = {
            alarmConfirmList: res.data.BJQR,
            alarmLevelList: res.data.BJDJ,
            disposeTypeList: res.data.CLLX,
            alarmSourceList: res.data.BJLY,
            timeoutTypeList: res.data.CSCL,
            disposeStatusList: res.data.BJCLZT,
            alarmStatusList: res.data.BJZT,
            alarmList: res.data.BJZL,
            disposeWayList: res.data.BJCLFS
          }
          this.dictObj = {
            disposeWayObj: formatDict(res.data.BJCLFS),
            alarmLevelObj: formatDict(res.data.BJDJ),
            disposeTypeObj: formatDict(res.data.CLLX),
            alarmSourceObj: formatDict(res.data.BJLY),
            timeoutTypeObj: formatDict(res.data.CSCL),
            disposeStatusObj: formatDict(res.data.BJCLZT),
            alarmStatusObj: formatDict(res.data.BJZT),
            D0008Obj: formatDict(res.data.D0008),
            D0009Obj: formatDict(res.data.D0009),
            D0010Obj: formatDict(res.data.D0010),
            typeObj: formatDict(
              [].concat(res.data.D0008, res.data.D0009, res.data.D0010)
            )
          }
          //处理状态 设置默认未处理
          this.form.disposeStatus = res.data.BJCLZT[0].dictCode

        }
      }).then(() => {
        this.getAlarmTypeFn()
        this.getAlarmData()
      })
    }
  },
  created () {
    this.form.system = sessionStorage.getItem('system').toString()
    this.alarmConfirmobj = formatDict(this.alarmConfirmList)
    this.getDicts()
    if (this.$route.query.type) {
      this.form.vehicleNos = [this.$route.query.vehicleNo];
      this.choosecph = [this.$route.query.cph]
      // this.search()
    }
  },
  mounted () {


  }
}
</script>

<style lang="scss" scoped>
.page-select {
  margin-right: 16px;
  /deep/.el-input__inner {
    width: 80px !important;
  }
}

.alarm-deal {
  height: 100%;
  display: flex;
  flex-direction: column;

  @include themify() {
    .content {
      flex: 1;
      background-color: #fff;
      padding: 16px;
      // display: flex;
      // flex-direction: column;
      // justify-content: space-between;
      .form {
        margin-bottom: 16px;
      }
      .pagination {
        margin-top: 16px;
        text-align: right;
        span {
          margin-right: 24px;
        }
      }
      .alarmName {
        color: themed('b4');
        cursor: pointer;
      }
      .alarm-btn {
        width: max-content;
        .el-button--small {
          padding: 9px 0.8vw;
        }
        .el-button + .el-button {
          margin-left: 0.5vw;
        }
      }
    }
  }
}
</style>
